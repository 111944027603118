import React, { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import {v4 as genId} from "uuid";
import { domain } from "../../../config.json"
import { Avatar } from '@/ui/Avatar';
import styles from './Widget.css';
import classNames from "classnames";

export const Widget = ({ type, id, ticket = genId(), title, thumb, task }) => {
  const [state, setOpen] = useState(false);

  if (ticket) ticket = '#' + ticket;

  return (
    <>
      <div className={classNames(styles.body, state && styles.open)}>
        {state && <iframe name={JSON.stringify(task)} className={styles.iframe} src={`${domain}/${type}/${id}${ticket || ''}`} />}
      </div>
      <Avatar title={state ? 'X' : title} thumb={thumb} onClick={() => setOpen(!state)} />
    </>
  )
}

for (let { node = null, project, task } of (window.juspWidget || [])) {
  if (!node) {
    node = document.createElement('div');
    node.dataset.name = "widget"
    node.className = styles.root;
    document.body.appendChild(node);
  }

  const root = createRoot(node);

  root.render(<Widget {...project} task={task}/>);
}