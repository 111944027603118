import React from 'react';
import { nameInitials } from '@/helpers/names';
import styles from './Avatar.css';
import classNames from 'classnames';
import { stringToColour } from '@/helpers/color';

export enum shapeType {
  round = 'round',
  square = 'square',
}

export enum sizeType {
  medium = 'medium',
  large = 'large',
}

interface IAvatar {
  title?: string;
  thumb?: string;
  className?: string;
  shape?: shapeType;
  size?: sizeType;
}

export const Avatar: React.FC<IAvatar> = props => {
  const { title = '', style = {}, className, onClick, shape = shapeType.round, size = sizeType.medium } = props;
  let { thumb } = props;

  if (!thumb) thumb = {};
  if (!thumb.color) thumb.color = stringToColour(props.title);

  const s = {
    color: thumb.color,
    backgroundImage: `linear-gradient(90deg, ${thumb.color}45 0%, ${thumb.color}35 100%)`,
    ...style
  };

  return (
    <div
      onClick={onClick}
      style={s}
      className={classNames(styles.root, styles[shape], styles[size], className)}
      children={nameInitials(title)}
    />
  );
};
